<template>
  <div class="row">
    <div class="col-md-5"></div>
    <div class="col-md-2">
      <b-form-group class="w-100 mb-3" id="input-from-date" v-if="filterDate == -1">
        <datePicker
          v-model="from"
          :config="dpOptions"
          placeholder="From Date"
          @dp-change="changeFilterDate(-1)"
        ></datePicker>
      </b-form-group>
    </div>
    <div class="col-md-2">
      <b-form-group class="w-100 mb-3" id="input-to-date" v-if="filterDate == -1">
        <datePicker
          v-model="to"
          :config="dpOptions"
          placeholder="To Date"
          @dp-change="changeFilterDate(-1)"
        ></datePicker>
      </b-form-group>
    </div>
    <div class="col-md-3">
      <b-dropdown block :text="filterDateText()" variant="outline-primary" class="text-primary mb-3" menu-class="w-100">
        <b-dropdown-item
          v-for="date in filterDateList"
          :active="activeFilteredDate(date)"
          :key="'filter_date_' + date.value"
          @click="changeFilterDate(date.value)"
          >{{ date.text }}</b-dropdown-item
        >
      </b-dropdown>
    </div>
    <div :class="`col-md-${user.role == 'athena' ? 4 : 3} col-sm-6`">
      <KTPortlet v-bind:class="'kt-portlet--height-fluid'" v-bind="{ bodyFit: true }">
        <template v-slot:body>
          <div class="kt-widget14" style="padding: 20px">
            <div class="kt-widget14__header" style="margin: 0; padding: 0">
              <h5 style="color: #db4437">Users</h5>
              <h1 style="color: #db4437">{{ total.user }}</h1>
            </div>
          </div>
        </template>
      </KTPortlet>
    </div>
    <div :class="`col-md-${user.role == 'athena' ? 4 : 3} col-sm-6`">
      <KTPortlet v-bind:class="'kt-portlet--height-fluid'" v-bind="{ bodyFit: true }">
        <template v-slot:body>
          <div class="kt-widget14" style="padding: 20px">
            <div class="kt-widget14__header" style="margin: 0; padding: 0">
              <h5 style="color: #3b5998">Users Bought Ticket</h5>
              <h1 style="color: #3b5998">{{ total.active_user }}</h1>
            </div>
          </div>
        </template>
      </KTPortlet>
    </div>
    <div :class="`col-md-${user.role == 'athena' ? 4 : 3} col-sm-6`">
      <KTPortlet v-bind:class="'kt-portlet--height-fluid'" v-bind="{ bodyFit: true }">
        <template v-slot:body>
          <div class="kt-widget14" style="padding: 20px">
            <div class="kt-widget14__header" style="margin: 0; padding: 0">
              <h5 class="text-success">Tickets</h5>
              <h1 class="text-success">{{ total.ticket }}</h1>
            </div>
          </div>
        </template>
      </KTPortlet>
    </div>
    <div class="col-md-3 col-sm-6" v-if="user.role !== 'athena'">
      <KTPortlet v-bind:class="'kt-portlet--height-fluid'" v-bind="{ bodyFit: true }">
        <template v-slot:body>
          <div class="kt-widget14" style="padding: 20px">
            <div class="kt-widget14__header" style="margin: 0; padding: 0">
              <h5 class="text-warning">Deposit</h5>
              <h1 class="text-warning">{{ total.deposit }}</h1>
            </div>
          </div>
        </template>
      </KTPortlet>
    </div>
    <div class="col-md-6 col-sm-12">
      <KTPortlet
        v-bind="{
          class: 'kt-portlet--fit kt-portlet--head-noborder',
          headClass: 'kt-portlet__space-x text-primary',
          fluidHalfHeight: false,
          headNoBorder: true,
          bodyFluid: true
        }"
      >
        <template v-slot:title>
          <h3 class="kt-portlet__head-title text-primary">Users By Platform</h3>
        </template>
        <template v-slot:body>
          <StackedBarChart
            title="User Register"
            :desc="barCharts.platform.stackDesc"
            :variant="barCharts.platform.color"
            :label="barCharts.platform.stackLabel"
            :data="barCharts.platform.stackData"
          ></StackedBarChart>
        </template>
      </KTPortlet>
    </div>
    <div class="col-md-6 col-sm-12">
      <KTPortlet
        v-bind="{
          class: 'kt-portlet--fit kt-portlet--head-noborder kt-portlet--height-fluid',
          headClass: 'kt-portlet__space-x text-danger',
          fluidHalfHeight: false,
          headNoBorder: true,
          bodyFit: true
        }"
      >
        <template v-slot:title>
          <h3 class="kt-portlet__head-title text-primary">Total Users By Platform</h3>
        </template>
        <template v-slot:body>
          <PieChart variant="danger" :data="pieCharts.total_platform"></PieChart>
        </template>
      </KTPortlet>
    </div>
    <div class="col-md-6 col-sm-12">
      <KTPortlet
        v-bind="{
          class: 'kt-portlet--fit kt-portlet--head-noborder',
          headClass: 'kt-portlet__space-x text-primary',
          fluidHalfHeight: false,
          headNoBorder: true,
          bodyFluid: true
        }"
      >
        <template v-slot:title>
          <h3 class="kt-portlet__head-title text-primary">Users By Channel</h3>
        </template>
        <template v-slot:body>
          <StackedBarChart
            title="User Register"
            :desc="barCharts.channel.stackDesc"
            :variant="barCharts.channel.color"
            :label="barCharts.channel.stackLabel"
            :data="barCharts.channel.stackData"
          ></StackedBarChart>
        </template>
      </KTPortlet>
    </div>
    <div class="col-md-6 col-sm-12">
      <KTPortlet
        v-bind="{
          class: 'kt-portlet--fit kt-portlet--head-noborder kt-portlet--height-fluid',
          headClass: 'kt-portlet__space-x text-danger',
          fluidHalfHeight: false,
          headNoBorder: true,
          bodyFit: true
        }"
      >
        <template v-slot:title>
          <h3 class="kt-portlet__head-title text-primary">Total Users By Channel</h3>
        </template>
        <template v-slot:body>
          <PieChart variant="danger" :data="pieCharts.total_channel"></PieChart>
        </template>
      </KTPortlet>
    </div>
    <div class="col-md-6 col-sm-12">
      <KTPortlet
        v-bind="{
          class: 'kt-portlet--fit kt-portlet--head-noborder',
          headClass: 'kt-portlet__space-x text-primary',
          fluidHalfHeight: false,
          headNoBorder: true,
          bodyFluid: true
        }"
      >
        <template v-slot:title>
          <h3 class="kt-portlet__head-title text-primary">User Bought Ticket</h3>
        </template>
        <template v-slot:body>
          <StackedBarChart
            title="User Register"
            :desc="barCharts.active_user.stackDesc"
            :variant="barCharts.active_user.color"
            :label="barCharts.active_user.stackLabel"
            :data="barCharts.active_user.stackData"
          ></StackedBarChart>
        </template>
      </KTPortlet>
    </div>
    <div class="col-md-6 col-sm-12">
      <KTPortlet
        v-bind="{
          class: 'kt-portlet--fit kt-portlet--head-noborder kt-portlet--height-fluid',
          headClass: 'kt-portlet__space-x text-danger',
          fluidHalfHeight: false,
          headNoBorder: true,
          bodyFit: true
        }"
      >
        <template v-slot:title>
          <h3 class="kt-portlet__head-title text-primary">Total User Bought Ticket</h3>
        </template>
        <template v-slot:body>
          <PieChart variant="danger" :data="pieCharts.total_active_user"></PieChart>
        </template>
      </KTPortlet>
    </div>
    <div class="col-md-6 col-sm-12">
      <KTPortlet
        v-bind="{
          class: 'kt-portlet--fit kt-portlet--head-noborder',
          headClass: 'kt-portlet__space-x text-primary',
          fluidHalfHeight: false,
          headNoBorder: true,
          bodyFluid: true
        }"
      >
        <template v-slot:title>
          <h3 class="kt-portlet__head-title text-primary">Tickets</h3>
        </template>
        <template v-slot:body>
          <StackedBarChart
            title="User Register"
            :desc="barCharts.ticket.stackDesc"
            :variant="barCharts.ticket.color"
            :label="barCharts.ticket.stackLabel"
            :data="barCharts.ticket.stackData"
          ></StackedBarChart>
        </template>
      </KTPortlet>
    </div>
    <div class="col-md-6 col-sm-12">
      <KTPortlet
        v-bind="{
          class: 'kt-portlet--fit kt-portlet--head-noborder kt-portlet--height-fluid',
          headClass: 'kt-portlet__space-x text-danger',
          fluidHalfHeight: false,
          headNoBorder: true,
          bodyFit: true
        }"
      >
        <template v-slot:title>
          <h3 class="kt-portlet__head-title text-primary">Total Ticket</h3>
        </template>
        <template v-slot:body>
          <PieChart variant="danger" :data="pieCharts.total_ticket"></PieChart>
        </template>
      </KTPortlet>
    </div>
    <div class="col-md-6 col-sm-12">
      <KTPortlet
        v-bind="{
          class: 'kt-portlet--fit kt-portlet--head-noborder',
          headClass: 'kt-portlet__space-x text-primary',
          fluidHalfHeight: false,
          headNoBorder: true,
          bodyFluid: true
        }"
      >
        <template v-slot:title>
          <h3 class="kt-portlet__head-title text-primary">Deposit</h3>
        </template>
        <template v-slot:body>
          <StackedBarChart
            title="User Register"
            :desc="barCharts.deposit.stackDesc"
            :variant="barCharts.deposit.color"
            :label="barCharts.deposit.stackLabel"
            :data="barCharts.deposit.stackData"
          ></StackedBarChart>
        </template>
      </KTPortlet>
    </div>
    <div class="col-md-6 col-sm-12">
      <KTPortlet
        v-bind="{
          class: 'kt-portlet--fit kt-portlet--head-noborder kt-portlet--height-fluid',
          headClass: 'kt-portlet__space-x text-danger',
          fluidHalfHeight: false,
          headNoBorder: true,
          bodyFit: true
        }"
      >
        <template v-slot:title>
          <h3 class="kt-portlet__head-title text-primary">Total Deposit</h3>
        </template>
        <template v-slot:body>
          <PieChart variant="danger" :data="pieCharts.total_deposit"></PieChart>
        </template>
      </KTPortlet>
    </div>
    <!-- <div class="col-md-6 col-sm-12" v-if="this.user.role !== 'athena'">
      <KTPortlet
        v-bind="{
          class: 'kt-portlet--fit kt-portlet--head-noborder kt-portlet--height-fluid',
          headClass: 'kt-portlet__space-x text-danger',
          fluidHalfHeight: false,
          headNoBorder: true,
          bodyFit: true
        }"
      >
        <template v-slot:title>
          <h3 class="kt-portlet__head-title text-primary">Total Users Offline</h3>
        </template>
        <template v-slot:body>
          <PieChart variant="danger" :data="pieCharts.total_offline"></PieChart>
        </template>
      </KTPortlet>
    </div> -->
  </div>
</template>
<script>
import { SET_BREADCRUMB } from '@/store/breadcrumbs.module';
import KTPortlet from '@/views/partials/content/Portlet.vue';
import StackedBarChart from '@/views/partials/widgets/StackedBarChart.vue';
import PieChart from '@/views/partials/widgets/PieChart.vue';
import ApiService from '@/common/api.service.js';
import { mapGetters } from 'vuex';
import { exportRequest } from '@/utils/download.js';
import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
export default {
  name: 'marketing_statistics',
  components: {
    KTPortlet,
    StackedBarChart,
    PieChart,
    datePicker
  },
  data() {
    return {
      dpOptions: {
        format: 'MM/DD/YYYY',
        sideBySide: true
      },
      totalUser: 10,
      barChartList: ['platform', 'channel', 'deposit', 'active_user', 'ticket'],
      pieChartList: [
        'total_platform',
        'total_channel',
        'total_deposit',
        'total_active_user',
        // 'total_user_bought',
        'total_ticket'
      ],
      from: '',
      to: '',
      barChartElement: {
        stackLabel: [],
        stackData: [],
        stackDesc: [],
        color: []
      },
      barCharts: {},
      pieCharts: {},
      total: {
        user: 0,
        active_user: 0,
        ticket: 0,
        // offline: 0
        deposit: 0
      },
      filterDateNum: null,
      dayNumList: [7, 15, 30],
      debounceTimer: null,
      filterDateList: [
        { value: 0, text: 'Today' },
        { value: 7, text: 'Last 7 days' },
        { value: 15, text: 'Last 15 days' },
        { value: 30, text: 'Last 30 days' },
        { value: -1, text: 'Custom' }
      ],
      filterDate: 30
    };
  },
  created() {
    for (let chart of this.barChartList) {
      this.barCharts = { ...this.barCharts, [chart]: this.barChartElement };
    }
    for (let chart of this.pieChartList) {
      this.pieCharts = { ...this.pieCharts, [chart]: [] };
    }
    this.changeFilterDate(this.filterDate);
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: 'Statistics',
        route: 'marketing-statistics'
      },
      { title: 'Management' }
    ]);
  },
  computed: {
    ...mapGetters(['layoutConfig']),
    user() {
      return this.$store.getters.currentUser.data;
    }
  },
  methods: {
    changeFilterDate(date) {
      this.filterDate = date;
      let from = new Date(this.from);
      let to = new Date(this.to);
      if (date >= 0) {
        from = new Date();
        to = new Date();
        from.setDate(from.getDate() - date);
      }
      if (date > 0) {
        to.setDate(to.getDate() - 1);
      }
      from.setHours(0, 0, 0, 0);
      to.setHours(23, 59, 59);
      this.from = new Date(from).toISOString();
      this.to = new Date(to).toISOString();
      this.getAllData();
    },
    filterDateText() {
      return this.filterDateList.find((date) => date.value == this.filterDate).text;
    },
    activeFilteredDate(date) {
      return this.filterDate == date.value;
    },
    chooseDateFilter(key) {
      if (this.filterDateNum == key) return;
      this.filterDateNum = key;
      // let { from, to } = this.getFromTo(new Date(), key);
      // this.to = to;
      // this.from = from;
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(async () => {
        await this.getAllData();
      }, 1000);
    },
    isActive(val) {
      return this.filterDateNum == val;
    },
    async getAllData() {
      let getBarChartDataList = this.barChartList.map(async (chart) => {
        let res = await this.getData(chart, this.from, this.to);
        return res;
      });
      let getPieChartDataList = this.pieChartList.map(async (chart) => {
        let res = await this.getData(chart, this.from, this.to);
        return res;
      });
      const barChartsData = await Promise.allSettled(getBarChartDataList);
      const pieChartsData = await Promise.allSettled(getPieChartDataList);
      const colorArr = [
        '#ffb822',
        '#FF7B89',
        '#CBE54E',
        '#5d78ff',
        '#0abb87',
        '#8950fc',
        '#e8ecfa',
        '#fd397a',
        '#5867dd',
        '#0abb87'
      ];
      let chartData = {};
      barChartsData.forEach((chartResp) => {
        let data = {
          stackLabel: [],
          stackData: [],
          stackDesc: [],
          color: []
        };
        let chart = chartResp.value;
        Object.values(chart.data).forEach((val) => {
          // console.log(chart.data[key]);
          Object.keys(val).forEach((desc) => {
            if (!data.stackDesc.includes(desc)) data.stackDesc = [...data.stackDesc, desc];
          });
        });
        data.stackData = new Array(data.stackDesc.length).fill([]);
        Object.keys(chart.data).forEach((key) => {
          data.stackLabel.push(key);
          data.stackDesc.forEach((desc, i) => {
            data.stackData[i] = [...data.stackData[i], chart.data[key][desc] || 0];
          });
        });
        let i = 0;
        data.color = data.stackDesc.map((desc) => {
          if (desc.toLowerCase().includes('google')) return '#DB4437';
          else if (desc.toLowerCase().includes('facebook')) return '#3b5998';
          else if (desc.toLowerCase().includes('sms')) return '#0abb87';
          else if (desc.toLowerCase().includes('app') && !desc.toLowerCase().includes('-')) return '#ffb822';
          // else if (desc.toLowerCase().includes('offline')) return '#8950fc';
          else {
            return colorArr[i++];
          }
        });
        chartData[chart.type] = { ...data };
      });
      this.barCharts = { ...chartData };
      let pieData = {};
      pieChartsData.forEach((chartResp) => {
        let chart = chartResp.value;
        let data = [];
        let i = 0;
        Object.keys(chart.data).forEach((key) => {
          let color = '';
          if (key.toLowerCase().includes('google')) {
            color = '#DB4437';
          } else if (key.toLowerCase().includes('facebook')) {
            color = '#3b5998';
          } else if (key.toLowerCase().includes('sms')) {
            color = '#0abb87';
          } else if (key.toLowerCase().includes('app')) {
            // color = '#8950fc';
            color = key.toLowerCase().includes('-') ? colorArr[i++] : '#ffb822';
          } else color = colorArr[i++];
          data.push({
            label: key,
            value: chart.data[key],
            color
          });
        });
        pieData[chart.type] = [...data];
        if (chart.type == 'total_platform')
          this.total = {
            ...this.total,
            user: pieData.total_platform.reduce((a, b) => a + b.value, 0).toLocaleString()
          };
        if (chart.type == 'total_active_user')
          this.total = {
            ...this.total,
            active_user: pieData.total_active_user.reduce((a, b) => a + b.value, 0).toLocaleString()
          };
        if (chart.type == 'total_ticket')
          this.total = {
            ...this.total,
            ticket: pieData.total_ticket.reduce((a, b) => a + b.value, 0).toLocaleString()
          };
        if (chart.type == 'total_deposit')
          this.total = {
            ...this.total,
            deposit: pieData.total_deposit.reduce((a, b) => a + b.value, 0).toLocaleString()
          };
      });
      this.pieCharts = { ...pieData };
    },
    getData(type = 'platform', from, to) {
      return new Promise((resolve) => {
        if (!from) {
          from = new Date();
          from = new Date(from.setDate(from.getDate() - 30)).toISOString();
        }
        if (!to) to = new Date().toISOString();
        ApiService.get('statistics/accounts', `?type=${type}&from=${from}&to=${to}`)
          .then((resp) => {
            resolve({ data: resp.data.data.data, type });
          })
          .catch((errors) => {
            this.$bvToast.toast(`${errors}`, {
              title: null,
              variant: 'danger',
              autoHideDelay: 3000,
              noCloseButton: true
            });
            resolve(false);
          });
      });
    },
    exportUser() {
      exportRequest(`/exports/customers?from=${this.from}&to=${this.to}`);
    }
  }
};
</script>
